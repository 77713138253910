:root {
  --background-color: rgb(219, 225, 219);
  --glow-color: #418141;
  --text-color:  #1c1c1c;
  --button-text-color: var(--text-color);
  --button-color: #deffd9; /* rgb(64, 70, 63);*/
  --button-text-color--hover: var(--button-color);
  --button-color--hover: var(--text-color);/* #ecfdea; */ /* rgb(43, 49, 41); */
  --button-text-color--active: #aecfa9;
  --button-color--active: #0c0c0c; /* #afc9ab; */ /* rgb(16, 20, 14);*/

  --panel-overlay-color: rgba(219, 225, 219, .75);

  --panel-background-color-light: rgb(159, 159, 159);
  --panel-background-color-dark:  #1c1c1c;
}

@font-face {
  font-family: 'Abel';
  src: url('static/fonts/abel/Abel-Regular.ttf') format("truetype"),
       url('static/fonts/abel/Abel-Regular.woff') format("woff"),
       url('static/fonts/abel/Abel-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('static/fonts/roboto-mono/RobotoMono-Regular.ttf') format("truetype"),
       url('static/fonts/roboto-mono/RobotoMono-Regular.woff') format("woff"),
       url('static/fonts/roboto-mono/RobotoMono-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto Mono';
  src: url('static/fonts/roboto-mono/RobotoMono-Italic.ttf') format("truetype"),
       url('static/fonts/roboto-mono/RobotoMono-Italic.woff') format("woff"),
       url('static/fonts/roboto-mono/RobotoMono-Italic.woff2') format("woff2");
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Roboto Mono';
  src: url('static/fonts/roboto-mono/RobotoMono-SemiBold.ttf') format("truetype"),
       url('static/fonts/roboto-mono/RobotoMono-SemiBold.woff') format("woff"),
       url('static/fonts/roboto-mono/RobotoMono-SemiBold.woff2') format("woff2");
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Roboto Mono';
  src: url('static/fonts/roboto-mono/RobotoMono-SemiBoldItalic.ttf') format("truetype"),
       url('static/fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff') format("woff"),
       url('static/fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff2') format("woff2");
  font-weight: 600;
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background:  rgb(13, 26, 1); */
  background:var(--background-color); /* #28311a; */
  color: var(--text-color);
  font-size: 12px;
}

.tick, .graph--label {
  font-family: 'Roboto Mono';
}

.tick, .network--graph--label {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, nav {
  font-weight: normal;
  font-family: 'Abel', sans-serif;
  font-size: 1.1rem;
  letter-spacing: .2px;
}

input {
  font: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
}

#controls {
  display: flex;
  flex-direction: row;
  margin: 0.5em 1em 0 1em;
  align-items: baseline;
}

#frequency-graph {
  flex: 0 0 200px;
  margin: 1em;
  position: relative;
}

#frequency-graph svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-height: 900px) {
  #frequency-graph {
    flex: 0 0 100px;
  } 
}

.button,
button,
select {
  font: inherit;
	margin: 0 0 0 .5em;
  padding: .5em 0.75em;
  border: none;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border-radius: 3px;
  cursor: default;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.button:hover,
button:hover,
select:hover {
  background: var(--button-color--hover);
	color: var(--button-text-color--hover);
  box-shadow: 0px 0px 3px var(--glow-color);
}
.button:active,
button:active,
select:active {
  background-color: var(--button-color--active);
  color: var(--button-text-color--active);
  box-shadow: none;
}
select {
	background-image: url(static/images/angle-down.svg);
	background-size: .75em;
	background-repeat: no-repeat;
	background-position-x: calc(100% - .75em);
	background-position-y: 1em;
	padding-right: 2em;
}
select:hover {
	background-image: url(static/images/angle-down--hover.svg);
	background-size: .75em;
	background-repeat: no-repeat;
	background-position-x: calc(100% - .75em);
	background-position-y: 1em;
}
.control--panel {
	margin: .25em;
}

.control--panel.time-filter {
  margin: .25em 1em;
}

.control--spacer {
  flex: 1 0;
}

.hashtag-filter select {
  margin-right: .5em;
  margin-left: .5em;
}

.hashtag-search {
  margin-left: 1em;
  flex: 0 0 20%;
  position: relative;
  display: flex;
	flex-direction: row;
	padding-bottom: .5em;
}
.hashtag-search--input {
	flex: 1 0;
}

.hashtag-search--suggestions{
	position: absolute;
	left: 0;
  right: 0px;
  color: var(--button-text-color);
	background: var(--button-color);
  padding-bottom: .5em;
  max-height: 200px;
  overflow: auto;
  top: calc(100% - .25em);
  z-index: 3;
}

.hashtag-search--suggestions--suggestion {
  padding: .5em .75em;
  cursor: default;
}
.hashtag-search--suggestions--suggestion:hover {
  background: var(--button-color--hover);
  color: var(--button-text-color--hover);
}
.hashtag-search--suggestions--suggestion:active {
	background: var(--button-color--active);
  color: var(--button-text-color--active);
	box-shadow: 0px 0px 1px var(--glow-color) inset;
}
.hashtag-filter {
  display: flex;
  flex: 1 0;
  align-items: center;
}
.hashtag-filter > * {
  flex: 0 0 auto;
}
.hashtag-filter--list {
  overflow: hidden;
  flex: 1 0;
  position: relative;
  height: 3.5em;
  margin-left: .5em;
}
.hashtag-filter--list::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 3em;
	background-image: linear-gradient(to right, transparent, var(--background-color) 80%);
}
.hashtag-filter--list:hover {
  overflow: initial;
}
.hashtag-filter--list-wrapper {
  position: absolute;
  display: inline-block;
	white-space: nowrap;
  padding: .75em .25em .25em .25em;
  border-radius: 3px;
  background: var(--button-color);
  color: var(--button-text-color);
}
/* .hashtag-filter--list-wrapper {
  max-width: 100%;
  white-space: initial;
} */
.hashtag-filter--list-wrapper:hover {
  max-width: 100%;
  white-space: initial;
  box-shadow: 0px 0px 2px var(--glow-color);
  z-index: 3;
  background: var(--button-color--hover);
  color: var(--button-text-color--hover);
}
.hashtag-filter--list--hashtag {
	display: inline-block;
	padding: .3em 2em .3em .5em;
	border: 1px solid currentColor;
	border-radius: 3px;
	margin: 0 .5em 0.5em .5em;
  cursor: default;
  position: relative;
}
.hashtag-filter--list button {
  border: 1px solid var(--button-text-color);
  margin-left: 1.5em;
  margin-right: .5em;
  padding-top: .3em;
  padding-bottom: .3em;
}
[data-filter-mode="or"] .hashtag-filter--list--hashtag:nth-child(n+2) {
  margin-left: 1.5em;
}
[data-filter-mode="or"] .hashtag-filter--list--hashtag:nth-child(n+2)::before {
  position: absolute;
  content: ' or ';
  right: calc(100% + .6em);
}
[data-filter-mode="and"] .hashtag-filter--list--hashtag:nth-child(n+2) {
  margin-left: 2.2em;
}
[data-filter-mode="and"] .hashtag-filter--list--hashtag:nth-child(n+2)::before {
  position: absolute;
  content: ' and ';
  right: calc(100% + .6em);
}
.hashtag-filter--list--hashtag:hover {
	/* background-color: #555555; */
	/*! text-decoration: ; */
}

.hashtag-filter--list--hashtag .inline-control {
  padding: .25em .5em;
  background-color: var(--button-color);
  color: var(--text-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.hashtag-filter--list--hashtag .inline-control:hover {
  background: var(--button-color--hover);
  color: var(--button-text-color--hover);
}

.hashtag-filter--list--hashtag .inline-control:active {
  background: var(--button-color--active);
  color: var(--button-text-color--active);
}

.hashtag-filter--list,
.hashtag-filter--controls {
	display: inline-block;
}

.hashtag-search--suggestions {
  display: none;
}

.hashtag-search:hover .hashtag-search--suggestions {
  display: block;
}

input {
  color: currentColor;
  background: inherit;
  border: 1px solid currentColor; 
  font-size: inherit;
  padding: .2em;
  vertical-align: middle;
}

textarea:focus, input:focus, select:focus, button:focus {
  outline: none;
}

#cooccurence--graph,
#cooccurence--network--graph {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#cooccurence--network--graph--container,
#cooccurence--graph--container {
  position: absolute;
  top: 3em;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--panel-background-color-dark);
  box-shadow: 0px 0px 4px var(--glow-color); 
}

main {
  display: flex;
  flex: 1 0;
  flex-direction: row;
  overflow: hidden;
  padding: 5px 0;
}

.status--list {
  flex: 0 300px;
  overflow-y: auto;
  background: var(--panel-background-color-dark);
  /* color: rgb(219, 225, 219); */
  color: var(--button-color);
  box-shadow: 0px 0px 4px var(--glow-color);
  margin: 0 1em;
}

.status--list a {
  color: currentColor;
}

.status--list a:hover {
  text-decoration: none;
}

.status--list--status {
  margin: 1em 1em;
  border-bottom: 1px dotted var(--button-color);
  padding-bottom: .5em;
}

.status--list button {
  display: block;
  margin: 1em;
  width: calc(100% - 2em);
}

.graphs {
  position: relative;
  flex: 1 0;
  color: var(--background-color);
  margin: 1em 1em 0 1em;
}

#cooccurence--graph--settings {
  position: absolute;
  top: -3rem;
  right: 0;
  color: var(--text-color);
  padding: .5em;
  z-index: 1;
  font-size: .85rem;
  display: flex;
}

/* #cooccurence--graph--settings > * {
  display: none;
}

#cooccurence--graph--settings:hover > * {
  display: initial;
}

#cooccurence--graph--settings:before {
  content: "...";
} */

#cooccurence--graph--settings:hover:before {
  display: none;
}

.active-graph--switch {
	display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  height: 2.25em;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  margin-left: -5px;
  margin-top: .5em; /* -5px; */
}

.active-graph--switch section {
	padding: .5em 2em;
	opacity: .8;
  cursor: pointer;
  display: flex;
  color: var(--text-color);
  border-top: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  border-left: 1px solid var(--text-color);
  align-items: flex-end;
}

.active-graph--switch section:hover, 
.active-graph--switch section[data-active="true"] {
	opacity: 1;
  z-index: 2;
	background: var(--panel-background-color-dark);
  color: var(--background-color);
  box-shadow: 0px 0px 3px var(--glow-color);
}

.topic--list {
  display: flex;
  align-items: baseline;
  margin: 0em 1em 0em 1em;
}

.topic--list--topic-hashtag--list {
  position: absolute;
  background: var(--panel-background-color-dark);
  box-shadow: 0px 3px 3px var(--glow-color);
  color: var(--background-color);
  padding: .5em;
  display: none;
  top: 100%;
  left: 0;
  z-index: 1;
  min-width: 250px;
}

.topic--list--topic:hover .topic--list--topic-hashtag--list {
  display: initial;
}

[data-as-installation="true"] .topic--list--topic:hover .topic--list--topic-hashtag--list {
  display: none;
}

.topic--list--topic-hashtag--list--hashtag {
  display: inline-block;
  margin-right: .25em;
}

.topic--list--topic {
	position: relative;
	cursor: pointer;
	padding: .3em .75em;
	border: 1px solid var(--button-text-color);
	border-radius: 3px;
	margin: 0 0 0 .5em;
}

.topic--list--topic:hover {
  background: var(--button-color--hover);
	color: var(--button-text-color--hover);
}

.topic--list--topic:active {
  background-color: var(--button-color--active);
  color: var(--button-text-color--active);
  box-shadow: none;
}

.topic--list--topic-hashtag--list--hashtag:after {
	content: ", ";
}

.topic--list--topic-hashtag--list--hashtag:last-child:after {
	content: "";
}

nav {
  background: rgb(22, 22, 22);
  color: var(--background-color);
  box-shadow: 0px 0px 3px var(--glow-color);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

nav header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

nav h1, nav h2 {
  margin: 0 .5em;
  text-transform: uppercase;
  font-size: 110%;
}

h2 {
  font-weight: normal;
}

nav ul {
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style-type: none;
  padding: 1em 1em .75em 1em;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.exporter {
  position: relative;
  display: inline-block;
}

.exporter .icon {
  width: .75em;
}

.exporter--actions {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  box-shadow: 0px 0px 3px var(--glow-color);
}

.exporter--actions button {
  white-space: nowrap;
}

.exporter--actions button + button {
  margin-top: .5em;
}

.exporter[data-expanded="true"] > button {
  background: var(--button-color--hover);
	color: var(--button-text-color--hover);
  box-shadow: 0px 0px 3px var(--glow-color);
}

.exporter[data-expanded="true"] .exporter--actions {
  display: flex;
  flex-direction: column;
  background: var(--panel-background-color-dark);
  padding: 1em;
  z-index: 2;
}

.exporter .exporter--background {
  display: none;
}

.exporter[data-expanded="true"] .exporter--background {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon--normal {
  display: initial;
}

.icon--hover {
  display: none;
}

:hover > .icon--normal {
  display: none;
}

:hover > .icon--hover {
  display: initial;
}

.control--graphsize label + label {
  margin-left: 1em 
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  padding: 3em;
  background-color: var(--panel-overlay-color);
}

.panel .panel--content {
  position: relative;
  background: var(--panel-background-color-dark);
  box-shadow: 0px 0px 3px var(--glow-color);
  max-width: 52em;
  margin: auto;
  overflow: auto;
  box-sizing: border-box;
  max-height: calc(100vh - 6em);
  color: var(--button-color);
  font-size: 120%;
  padding: 3em 5em 0em 5em;
}

.panel--content a {
  color: currentColor;
}

.panel--content a:hover {
  text-decoration: none;
}

.panel--content img {
  max-width: 100%;
  margin: 2em 0;
}

.panel--content > :last-child {
  margin-bottom: 5em;
}

.panel--close {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}

.panel--close:hover {
  color: var(--button-text-color--active);
}

[data-loading] {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1500px) {
  .graphs {
    margin-top: 2.5rem;
  }

  #cooccurence--graph--settings {
    top: -5rem;
  }
}

#hashtag-search--onscreen-keyboard {
  position: absolute;
  bottom: calc(100% + 1em);
  right: 0;
  width: 800px;
  display: none;
}

#hashtag-search--onscreen-keyboard[data-open="true"] {
  display: block;
}

#hashtag-search--onscreen-keyboard .hg-theme-default .hg-button.hg-button-space {
  flex-grow: 6;
}

#hashtag-search--onscreen-keyboard .hg-theme-default .hg-button.hg-button-close {
  flex-grow: 2;
}

#hashtag-search--onscreen-keyboard .hg-theme-default {
  background-color: var(--button-color);
  padding: 10px;
}
/*
#hashtag-search--onscreen-keyboard  .hg-theme-default .hg-button {
  background-color: var(--button-color--active);
  color: var(--button-color);
} */

.control--more {
  position: relative;
  display: inline-block;
}

.control--more .icon {
  width: .75em;
}

.control--more--content {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  box-shadow: 0px 0px 3px var(--glow-color);
  color: var(--button-color);
}

.control--more--content button {
  white-space: nowrap;
}

.control--more--content button + button {
  margin-top: .5em;
}

.control--more--content .control label {
  white-space: nowrap;
}

.control--more--content .control:nth-child(n+2) {
  margin-top: .5em;
}

.control--more .control--more--background {
  display: none;
}

.control--more[data-expanded="true"] .control--more--background {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.control--more[data-expanded="true"] .control--more--content {
  display: flex;
  flex-direction: column;
  background: var(--panel-background-color-dark);
  padding: 1em;
  z-index: 2;
}

.control--more[data-expanded="true"] .button,
.control--more[data-expanded="true"] button {
  background: var(--button-color--hover);
	color: var(--button-text-color--hover);
  box-shadow: 0px 0px 3px var(--glow-color);
}
